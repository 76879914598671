import React from "react"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <h1>Hello (other) world!</h1>
      <h2>Hello other world</h2>
      <h3>Hello other world</h3>
      <h4>Hello other world</h4>
      <h5>Hello other world</h5>
      <h6>Hello other world</h6>
      <p>the quick little brown fox is a jumpy dog dog dog dog</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam cursus
        purus non vulputate dapibus. Praesent mattis malesuada risus nec
        fermentum. Nunc egestas nunc lectus, at fringilla augue rhoncus sit
        amet. Sed congue lectus orci, ac dapibus lorem ultrices ac. Cras finibus
        mauris ac rhoncus rhoncus. Praesent consectetur scelerisque erat et
        mattis. Quisque ultricies, est ac elementum aliquet, ligula quam
        ultrices ligula, eget laoreet nisi ante et risus. Vestibulum nec iaculis
        sem. Fusce tempor lectus vitae leo aliquet, sit amet vehicula velit
        sodales. Sed tincidunt eu urna id iaculis. Integer eget purus convallis,
        dictum metus ut, mattis enim. Donec vestibulum dolor at turpis tempus
        mollis.
      </p>
      <p>
        Fusce sed eros vel metus interdum maximus sed vitae ipsum. Donec id
        aliquam odio. Aliquam gravida sit amet lacus sit amet posuere. Phasellus
        turpis nulla, bibendum at viverra quis, finibus at libero. Donec lectus
        augue, ultricies et tortor id, ultricies iaculis dolor. In tristique
        magna molestie, lobortis tellus blandit, hendrerit diam. Nulla venenatis
        tempor justo, id porttitor eros mollis eu. Nulla at sodales neque, vel
        dignissim nunc. Ut tristique urna ut ipsum lobortis, a congue velit
        varius. Curabitur porttitor leo vitae tellus sodales pellentesque.
        Curabitur vel lacus in erat consectetur tincidunt.
      </p>
      <p>
        Donec scelerisque elit erat, ac malesuada dolor maximus et. Cras non
        tincidunt felis, vel dignissim quam. Sed luctus risus interdum velit
        molestie auctor quis eu turpis. Cras vestibulum nisi sit amet aliquam
        pellentesque. Fusce tincidunt rhoncus felis, ut tristique ligula
        convallis gravida. Quisque id ex pulvinar, feugiat erat in, tempor
        magna. Integer congue felis id viverra eleifend. Nulla augue erat,
        malesuada tincidunt egestas vel, luctus vitae elit. Nullam et sodales
        nibh, in ultrices enim. Proin feugiat tincidunt lectus, imperdiet
        convallis felis placerat ac. Donec elementum leo pretium dapibus
        tincidunt. Quisque ut leo eu justo rhoncus lacinia. Maecenas
        condimentum, ligula eu finibus aliquet, lorem purus rutrum nisi, et
        volutpat leo mauris a lorem.
      </p>
      <p>
        Fusce vitae sapien est. Suspendisse feugiat, justo nec tempus dictum,
        tellus odio elementum quam, non hendrerit ipsum justo eget nisl. Ut
        mollis felis non tempor suscipit. Integer sed velit non urna dignissim
        sagittis. Aliquam scelerisque est feugiat erat sollicitudin varius. Nam
        rhoncus eu felis sed egestas. Vivamus in luctus purus. Vestibulum magna
        sem, placerat id cursus at, viverra at lacus.
      </p>
      <p>
        Vestibulum quis diam in ante maximus porttitor ut condimentum magna. Nam
        porttitor urna nibh, eget feugiat velit luctus consequat. Nullam
        convallis tellus at metus vehicula, quis convallis purus commodo. Fusce
        accumsan felis mauris, id faucibus est viverra ut. In posuere aliquam
        nibh, at tempor ex. Nulla facilisi. Etiam ullamcorper bibendum ipsum, ac
        vestibulum elit mollis ut. Sed maximus risus tristique ullamcorper
        ultricies. Donec venenatis condimentum diam, ac gravida tellus placerat
        facilisis. Nunc accumsan sem sit amet nisi suscipit, ut eleifend urna
        finibus. Nam fermentum lacus at elit molestie luctus. Aenean mattis nisi
        et velit porta egestas. Sed lectus neque, interdum at metus sed, congue
        aliquam sapien.
      </p>
      <a href="#hi" id="hi">
        link to nowhere
      </a>
      <ul>
        <li>unordered</li>
        <li>list</li>
        <li>cool, huh</li>
      </ul>
      <ol>
        <li>ordered</li>
        <li>list</li>
        <li>also</li>
        <li>not bad</li>
      </ol>
    </Layout>
  )
}
